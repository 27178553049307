export const configTheme = {
  components: {
    Checkbox: {
      colorPrimary: '#FECD0E',
      colorPrimaryHover: 'black',
    },
    Radio: {
      colorPrimary: '#FECD0E',
      colorPrimaryHover: 'black',
    },
    Button: {
      colorPrimary: '#FECD0E',
      primaryColor: '#000000',
      colorPrimaryHover: '#FFE060',
      colorPrimaryActive: '#FFE060',
    },
    Input: {
      inputFontSize: 13,
      paddingBlock: 5,
      hoverBorderColor: '#1A1A1A',
      activeBorderColor: '#1A1A1A',
    },
    Card: {
      headerBg: '#FECD0E !important'
    },
    Form: {
      labelFontSize: 13,
      labelColor: '#1A1A1A',
      verticalLabelPadding: '0 0 4px',
      itemMarginBottom: 20,
    },
    Spin: { colorPrimary: '#1A1A1A' },
    Tabs: {
      itemActiveColor: "#FECD0E",
      itemSelectedColor: "#FECD0E",
      inkBarColor: "#FECD0E",
      verticalItemPadding: "20px 0"
    }
  },
  token: {
    // TODO
    // colorTextPlaceholder: '#red',
    // colorText: 'red',
    // colorPrimary: 'red',
    // colorBgContainer: 'red',
  },
};
