import { USER_TYPE } from 'constants/common.const';
import { STORAGE } from 'constants/storage.const';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import storage from 'utils/LocalStorage';

export interface IProps {
  children?: JSX.Element;
  checkAdmin?: boolean;
}

const PrivateRoute = ({ children, checkAdmin }: IProps) => {
  const isAuth = useMemo(() => {
    const tokenLocal = storage.get(STORAGE.TOKEN);
    const roles = storage.get(STORAGE.ROLES);
    if (
      !tokenLocal ||
      (tokenLocal && roles === USER_TYPE.STUDENT && checkAdmin)
    ) {
      window.location.href = '/login';
      localStorage.clear();
      return false;
    }

    return true;
  }, []);

  return isAuth ? <>{children || <Outlet />}</> : <></>;
};
export default PrivateRoute;
