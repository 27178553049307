import { Flex } from 'antd';
import LayoutAdmin from 'layout/admin/LayoutAdmin';
import LayoutClient from 'layout/client/LayoutClient';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import {
  AsyncHomePage,
  AsyncLoginPage,
  AsyncQuizPage,
  AsyncRegisterPage,
  AsyncResultPage,
  AsyncTestResultsEditPage,
  AsyncTestResultsPage,
  AsyncTestsDetailPage,
  AsyncTestsPage,
  AsyncUserListPage,
  AsyncViewSpeakingPage,
  AsyncViewWritingPage,
} from './elements';
import PrivateRoute from './PrivateRoute';
import LayoutResult from 'layout/result/LayoutResult';

export const routes = [
  {
    path: '/login',
    element: (
      <div>
        <AsyncLoginPage />
      </div>
    ),
  },
  {
    path: '/register',
    element: (
      <div>
        <AsyncRegisterPage />
      </div>
    ),
  },

  {
    path: 'tests',
    element: (
      <PrivateRoute>
        <LayoutClient>
          <div className="px-24">
            <Outlet />
          </div>
        </LayoutClient>
      </PrivateRoute>
    ),
    children: [
      {
        path: '',
        element: (
          <div>
            <AsyncTestsPage />
          </div>
        ),
      },
      {
        path: ':code',
        children: [
          {
            path: '',
            element: (
              <div>
                <AsyncTestsDetailPage />
              </div>
            ),
          },
          {
            path: ':testId',
            element: (
              <div>
                <AsyncQuizPage />
              </div>
            ),
          },
        ],
      },
    ],
  },

  {
    path: 'admin',
    element: (
      <PrivateRoute checkAdmin>
        <LayoutAdmin />
      </PrivateRoute>
    ),
    children: [
      {
        path: 'test-results',
        children: [
          {
            path: '',
            element: <AsyncTestResultsPage />,
          },

          {
            path: 'view-writing/:userId/:testCode/:skill',
            element: <AsyncViewWritingPage />,
          },
          {
            path: 'view-speaking/:userId/:testCode',
            element: <AsyncViewSpeakingPage />,
          },
          {
            path: ':userId',
            element: <AsyncTestResultsEditPage />,
          },
          // {
          //   path: ":userId",
          //   element: <Result />,
          // },
        ],
      },
      {
        path: 'user-list',
        element: (
          <div>
            <AsyncUserListPage />
          </div>
        ),
      },
      {
        path: 'register',
        element: (
          <div>
            <AsyncRegisterPage />
          </div>
        ),
      },
    ],
  },

  {
    path: 'result',
    element: (
      <PrivateRoute>
        <LayoutResult>
          <Suspense fallback={<div />}>
            <Outlet />
          </Suspense>
        </LayoutResult>
      </PrivateRoute>
    ),
    children: [
      {
        path: ':userId',
        element: <AsyncResultPage />,
      },
      {
        path: 'view-writing/:userId/:testCode/:skill',
        element: (
          <Flex className="p-24">
            <AsyncViewWritingPage />
          </Flex>
        ),
      },
      {
        path: 'view-speaking/:userId/:testCode',
        element: (
          <Flex className="p-24">
            <AsyncViewSpeakingPage />
          </Flex>
        ),
      },
    ],
  },
  {
    path: '/',
    element: <AsyncHomePage />,
  },
  {
    path: '/*',
    element: <div>not page</div>,
  },
];
